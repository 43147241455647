var getDomain = function(profreg){
    var _domain ="";
    if (window.location.host.split('.').length == 4){
        _domain = window.location.host.split('.')[1] + ".";
    }
    if (window.location.host.split('.').length == 5){
        _domain = window.location.host.split('.')[1] + "."+ window.location.host.split('.')[2] + ".";
    }
    if(profreg=="profreg"||profreg=="login"){
        _domain = _domain.replace('staging.','').replace('proddev.','');
    }
    return _domain;
}
var savedInfoEndPoint = "//www."+getDomain()+"medscape.org/useractivityservice/"; 

var getUserActivities = {
	config : {
		getPath : "",
		theData : "",
		retryCount : 0
	},
	star : function(articles){
		var starPath = "get/cmeSaveActivities?active=1&q=url:"+articles
		getUserActivities.starAjax(starPath);	
	},
	get : function(amount,article,txnget,element,offset,limit,source){
		var checkByArticle = '';
		var offsetBy = '';
		var limitBy = '';
		if (article !== undefined && article !== "") {
			checkByArticle = "&q=url:"+article;
		}
		if (offset !== undefined && offset !== ""){
			offsetBy = "&offset="+offset;
		}
		if (limit !== undefined & limit !== ""){
			limitBy = "&limit="+limit
		}
		
		
		if (amount !== undefined && amount !== "") {
			if (amount == "all") {
				getUserActivities.config.getPath = "get/cmeSaveActivities?all=true&active=1"+limitBy+checkByArticle+offsetBy;
			}
			else {
				getUserActivities.config.getPath = "get/cmeSaveActivities?limit="+amount+"&active=1"+limitBy+checkByArticle+offsetBy;
			}
		}
		else {
			getUserActivities.config.getPath = "get/cmeSaveActivities?active=1"+limitBy+checkByArticle+offsetBy;
		}
		getUserActivities.ajax(txnget,element,source);
		return getUserActivities.config.theData;
	},
	ajax : function(txnget,element,source) {
		$.ajax({
			async: false,
			cache: false,
			type: 'GET',
			contentType: 'application/json',
			dataType: "json",
			url: savedInfoEndPoint + getUserActivities.config.getPath,
			xhrFields: {
				withCredentials: true
			},
			crossDomain: true,
			success: function(json) {
				getUserActivities.config.theData = json;
				if (txnget === 'txnget' && txnget !== "") {
					if (json.data.length >0) {
						var txnId = json.data[0].userTxnId;
						$(element).attr('id','txn'+txnId+'-activity');
						getUserActivities.config.retryCount = 0;
					}
					else if (getUserActivities.config.retryCount < 5) {
						getUserActivities.config.retryCount++;
						saveTxnId(element);
					}
					else {
						getUserActivities.config.retryCount = 0;
						$(element).removeClass("saved");
						$(element).html('<span class="star-blue"></span><br />Save');
						$(element).closest('.card').append('<div class="error-saving-msg">Error Saving: Please try again later</div>')
						$('.error-saving-msg').css('opacity','1');
						setTimeout(function(){
							$('.error-saving-msg').css('opacity','0');
						},2000);
						setTimeout(function(){
							$('.error-saving-msg').remove();
						},2300);						
					}
				}
				if (typeof savedCardsHomepage !== 'undefined' && txnget !== 'txnget') {
					if (typeof json.count === 'undefined') {
						savedCards.config.count = 0;
					}
					else {
						savedCards.config.count = json.count;
					}
					savedCardsHomepage.cardMarkup(json);
				}
				if (source === "indexpage" && txnget !== 'txnget') {
					if (typeof json.count === 'undefined') {
						savedCardsIndex.config.count = 0;
					}
					else {
						savedCardsIndex.config.count = json.count;
					}
					savedCardsIndexHomepage.cardMarkup(json);
					
				}

				/* Frontend recursive retry
				if (json.length < 1 && getUserActivities.config.retryCount < 3)  {
					console.log('frontend retry attempted');
					console.log(savedInfoEndPoint + getUserActivities.config.getPath);
					getUserActivities.config.retryCount++;
					setTimeout(getUserActivities.ajax, 3000);
				}
				*/
			},
			error: function(jqXHR, textStatus, errorThrown) {
				//var intentionally hoisted to global. Retrievable for debugging and tracking error types.
				savedErrorMsg = "Error connecting to service - " + errorThrown + ", " + textStatus + ", " + jqXHR;
				return "error";
			}
		});
	},
	starAjax : function(path) {
		$.ajax({
			async: false,
			cache: false,
			type: 'GET',
			contentType: 'application/json',
			dataType: "json",
			url: savedInfoEndPoint + path,
			xhrFields: {
				withCredentials: true
			},
			crossDomain: true,
			success: function(json) {
				//console.log(json)
				var txnMatch = [];
				$.each(json.data,function(id){
					var txnId = this.userTxnId;
					var cleanedId = this.jsonData.url;
					cleanedId = String(cleanedId.replace('/viewarticle/',''));
					$('#activities .cards .card .activityTitle a').each(function(){ //for each card in the activities section
						var self = this;
						if ($(this).attr('href').indexOf(cleanedId) > -1) { //if the current card contains the saved id
							$(self).closest('.card').find('.activityData').find('.saveState').html('<span class="star-blue"></span><br />Saved').attr('id','txn'+txnId+'-activity').addClass('saved');
						}
					});
				});
				
				$('.cards .card .activityTitle .a').each(function(){
					var theId = $(this).attr('href').replace('/viewarticle/','');
				});
			},
			error: function(jqXHR, textStatus, errorThrown) {
				//var intentionally hoisted to global. Retrievable for debugging and tracking error types.
				savedErrorMsg = "Error connecting to service - " + errorThrown + ", " + textStatus + ", " + jqXHR;
				return "error";
			}
		});
	}	
}

var saveUserActivities = {
	config : {
		savePath : "save",
		updatePath : "updateactiveflag"
	},
	save : function(content, element){
		//setup the payload jsonData here
		var string = JSON.stringify(content)
		var payload = '[{"active": 1,"createdOn": "","jsonData": '+string+',"tagName": "cmeSaveActivities","updatedOn": "","userTxnId": 0}]';
		var actionname = "save"
		saveUserActivities.ajax(payload,saveUserActivities.config.savePath, actionname, element);
	},
	update : function(txnId) {	
		//target the transaction ID of the article being "updated"
		var payload = '[{	"userTxnId": '+txnId+', "tagName": "cmeSaveActivities", "active": 0}]'
		var actionname = "update";
		return saveUserActivities.ajax(payload,saveUserActivities.config.updatePath, actionname);
	},
	ajax : function(payload, action, actionname, element) {
		return 	$.ajax({
			cache: false,
			type: 'POST',
			contentType: 'application/json',
			data: payload,
			dataType: "json",
			url: savedInfoEndPoint + action,
			xhrFields: {
				withCredentials: true
			},
			crossDomain: true,
			success: function(json) {
				if (actionname === 'save') {
					saveTxnId(element);
				}
				if (typeof savedCards !== 'undefined') {
					savedCards.init();
					$('.saved-card').addClass('ready-two');	
				}
			},
			error: function(jqXHR, textStatus, errorThrown) {
				//saveTxnId(element)
				//var intentionally hoisted to global. Retrievable for debugging and tracking error types.
				savedErrorMsg = "Error connecting to service - " + errorThrown + ", " + textStatus + ", " + jqXHR;
				return "error";
			}
		});
	}
}


var saveTxnId = function(element) {
	var articleId = $(element).closest('.card').find('.activityTitle').find('a').attr('href');
	var getTransaction = getUserActivities.get(1,articleId,'txnget',element);

}